var viewCounter = 1;
$(document).on( 'scroll', function(){
  if($('.counter-count').isOnScreen() && viewCounter == 1){
    viewCounter++;
    $('.counter-count').each(function () {
      $(this).prop('Counter',0).animate({
          Counter: $(this).text()
      }, {
          duration: 5000,
          easing: 'swing',
          step: function (now) {
            $(this).text(Math.ceil(now));
          }
      });
    });
  }
});

$.fn.isOnScreen = function(){
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};